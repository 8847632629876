import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';
import { QR } from './pages/QR';
import { PaymentSuccess, PaymentError } from './pages/PaymentSuccess';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import './App.css';

/* Theme variables */
import './theme/variables.css';
import { Miho } from './components/miho';

setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/home"> 
          <Home />
        </Route>
        <Route exact path="/qr"> 
          <QR />
        </Route>
        <Route exact path="/miho"> 
          <Miho />
        </Route>
        <Route exact path="/paymentSuccess" 
          render={(props) => { return <PaymentSuccess {...props} />}}
        /> 
        <Route exact path="/paymentError"> 
          <PaymentError />
        </Route>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route path='/android' component={() => {
            window.location.href = 'https://play.google.com/store/apps/details?id=io.ionic.stng';
          return null;
        }}/>
        <Route path='/ios' component={() => {
            window.location.href = 'https://apps.apple.com/ru/app/%D1%81%D1%82%D0%BD%D0%B3/id6445904988';
          return null;
        }}/>
        <Route path='/return' component={() => {
            window.location.href = 'ionic://app.aostng.ru';
          return null;
        }}/>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
