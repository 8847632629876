import { IonPage} from '@ionic/react';
import { useState } from 'react';
import { Login, User } from '../components/Login';
import { MainWindow } from '../components/Full';
import { Store } from './Store';
import { Phone } from '../components/Phone';
import { Pad } from '../components/Pad';
import './Home.css';


const Home: React.FC = () => {
  const [ auth, setAuth ] = useState( Store.getState().auth )

  Store.subscribe({num: -1, type: "auth", func: ()=>{
    setAuth( Store.getState().auth )
  }})

  function Main():JSX.Element {
    let w = window.innerWidth
    console.log(w)
    let elem = <></>
    if(w < 450) 
        elem = <Phone />
    else if (w < 1200) 
        elem = <Pad />
    else 
        elem = <MainWindow/>
    return elem
  }
  console.log( auth )
  return (
    <IonPage>
      { auth === 1
        ? <Main />
        : auth === 2 
        ? <User />
        : <Login />
      }
    </IonPage>
  );
};

export default Home;
