import { FilePicker } from "@capawesome/capacitor-file-picker"
import { IonButton, IonContent, IonModal } from "@ionic/react"
import React, { useState } from "react"
import { saveAs } from 'file-saver'

 export function Miho(){
    const [ info, setInfo ] = useState<any>([])
    const [ text, setText ] = useState<any>([])
    
    
    async function readDir(){
        const res = await FilePicker.pickFiles({
                types: [ 'image/png', 'image/jpg', 'image/jpeg', 'text/plain' ], 
                multiple: true
        })
        let images: any = []
        res.files.forEach(elem => {
            if(elem.mimeType === 'text/plain'){
                const reader = new FileReader()
                reader.onloadend = ()=> { 
                    let jarr: any = (reader.result as string).split('\n')
                    jarr = jarr.map((el)=>{
                        const jrr = el.split('\t')
                        const lst = jrr[2] === undefined ? '0' : jrr[2].includes('\r') ? jrr[2].split('\r')[0] : jrr[2]
                        return { 
                            class: jrr[0], 
                            foto1: jrr[1] === undefined ? '0' : jrr[1] === '' ? '0' : jrr[1], 
                            foto2: lst === '' ? '0' : lst
                        }
                    })
                    jarr.shift();
                    while(jarr[ jarr.length - 1].class === '') jarr.pop();
                    setText( jarr );
                    console.log(jarr)
                }
                reader.readAsText(elem.blob as Blob );
            } else {
               images = [...images, elem ] 
            }                
        });
        setInfo( images )
        console.log( images)

        
    }

    async function saveDir() {
        text.forEach((elem, i) => {
            if( info[i * 2] !== undefined ) {
                const name = elem.class + "-" + elem.foto1  + '.' + info[i * 2].name.split('.')[1]
                console.log(info[i*2].name + ' --> ' + name)
                //saveAs('D:/mihofly/results/', name);
            }
            if( info[i * 2 + 1] !== undefined ) {
                const name = elem.class + "2-" + elem.foto2  + '.' + info[i * 2 + 1].name.split('.')[1]
                console.log(info[i*2 + 1].name + ' --> ' + name)
                //saveAs('D:/mihofly/results/', name);
            }
            
        });
    }

    function Text(props: { text, info }){
        const [ text ]      = useState( props.text )
        const [ info ]      = useState( props.info )
        const [ modal, setModal ]   = useState<any>()

        async function getImg( blob : Blob ){
            const reader = new FileReader()
            reader.onloadend = ()=> { 
                setModal(reader.result)
            }
            reader.readAsDataURL( blob );

        }

        let elem = <></>

        for(let i = 0; i < text.length; i++){
            elem = <>
                { elem }
                <div className="flex fl-space ml-3">
                    <div className="flex mt-05">
                        <div className="w-4">{ text[i].class }</div>
                        <div className="ml-2">{ '[' + text[i].foto1 + ', ' + text[i].foto2 + ']' }</div>
                    </div>
                    <div className="flex w-70 mt-05">
                        <div className="w-20 mr-1">
                            {
                                info[ i * 2 ] !== undefined 
                                    ? <>
                                        <div className="flex borders-wp pt-05 pl-1 pb-05 point"
                                            onClick={()=>{
                                               getImg( info[i * 2].blob)     
                                            }}
                                        >
                                            <div>{ text[i].class + " - " + text[i].foto1 + "." + info[i * 2].name.split('.')[1] }</div>
                                        </div>
                                        
                                    </>
                                    : <></>
                            }
                        </div>                    
                        <div className="flex w-20">
                            {
                                info[ i * 2 + 1 ] !== undefined 
                                    ? <>
                                        <div className="flex borders-wp w-100 pt-05 pl-1 pb-05 point"
                                            onClick={()=>{
                                                getImg( info[i * 2 + 1].blob)     
                                             }}
                                         >
                                            <div>{ text[i].class + "2 - " + text[i].foto2 + "." + info[i * 2 + 1].name.split('.')[1] }</div>
                                        </div>
                                    </>
                                    : <></>
                            }
                        </div>                    
                    </div>
                </div>
                <IonModal
                    className="w-100 h-100"
                    isOpen = { modal !== undefined }
                    onDidDismiss={ () => setModal( undefined )}
                >
                    <div className="w-100 h-100">
                        {  
                            <img src={ modal } alt = "" className="w-100"/>
                        }
                    </div>
                </IonModal>
            </>
        }

        return elem
    }

    const elem = <>
        <div className='pl-1 pt-1 pr-1'>
            <div className='t-underline'>
                Фотографии
            </div>
            <div className='flex pl-1 pt-1 pr-1'> 
                <IonButton
                    onClick={()=>{
                        readDir()
                    }}
                >
                    Прочитать файлы
                </IonButton>
                <IonButton
                    onClick={()=>{
                        saveDir()
                    }}
                >
                    Сохранить файлы
                </IonButton>
            </div>
       </div>
       <IonContent>
            <Text text = { text } info = { info } />
        </IonContent>
    </>

    return elem
 }