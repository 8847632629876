import { isPlatform } from '@ionic/react';
import { getPlatforms } from '@ionic/react';
import { Redirect } from 'react-router';

export function QR():JSX.Element {
    
    console.log("asas")
    console.log( getPlatforms() )

    let elem = <>
        {   
            isPlatform("ios") 
            ? <Redirect from="/" to="/ios" />
            : <Redirect from="/" to="/android" />
        }
        
    </>

    return elem
}