import { combineReducers  } from 'redux'
import axios from 'axios'
import { Reducer } from 'react';


var reducers: Array<Reducer<any, any>>;reducers = []

export const i_state = {

    auth:                        0,
    route:                          "",
    login:                          {
          Код: "", Наименование: "",  Логин: "", элПочта : ""
        , Картинка: "", Роль: "user", Пинкод: ""
    },
    grid1:                          {
        header: 'Поставка газа в МГ от ПАО "ЯТЭК"',
        data: [
            ["", "Суточный", "Месячный", "Годовой"],
            ["План", 0, 0, 0],
            ["Факт 2022", 0, 0, 0],
            ["Факт 2021", 0, 0, 0],
        ]
    },
    grid2:                          {
        header: 'Выпуск СПБТ, тонн',
        data: [
            ["Суточный", "Месячный", "Годовой"],
            [58.358, 955.72, 5741.58],
            [62.730, 1012.18, 5828],
            [57.460, 1040.72, 1512.72],
        ]
    },
    grid3:                          {
        header: 'Реализация СПБТ, тонн',
        data: [
            ["Суточный", "Месячный", "Годовой"],
            [58.358, 445.72, 5774.58],
            [66.730, 181.18, 5844],
            [56.460, 190.72, 1595.72],
        ]
    },
    miss1:                          [
        { name: "Командировка",   amount: 5, Ok:    false, data: [
            {name: "Иванов Иван Иванович",      role: "ЭлектроГазосварщик", beginDate: "28.03.2022"},
            {name: "Петров Петр Петрович",      role: "ЭлектроГазосварщик", beginDate: "28.03.2022"},
            {name: "Сидоров Сидор Сидорович",   role: "ЭлектроГазосварщик", beginDate: "28.03.2022"},
            {name: "Иванов Иван Иванович",      role: "ЭлектроГазосварщик", beginDate: "28.03.2022"},
            {name: "Сидоров Сидор Сидорович",   role: "ЭлектроГазосварщик", beginDate: "28.03.2022"},
        ]},
        { name: "Больничный",     amount: 3, Ok:    false, data: [
            {name: "Иванов Иван Иванович",      role: "ЭлектроГазосварщик", beginDate: "28.03.2022"},
            {name: "Петров Петр Петрович",      role: "ЭлектроГазосварщик", beginDate: "28.03.2022"},
            {name: "Сидоров Сидор Сидорович",   role: "ЭлектроГазосварщик", beginDate: "28.03.2022"},
        ]},
        { name: "Отпуск",         amount: 2, Ok:    false, data: [
            {name: "Иванов Иван Иванович",      role: "ЭлектроГазосварщик", beginDate: "28.03.2022"},
            {name: "Петров Петр Петрович",      role: "ЭлектроГазосварщик", beginDate: "28.03.2022"},
        ]},
    ],
    vacanse1:                       [
        { name: "АУП",            amount: 5, Ok: false, data: [
            {name: "Бухгалтер",                 role: "4", beginDate: "28.03.2022"},
            {name: "Уборщик",                   role: "5", beginDate: "28.03.2022"},
            {name: "Инженер",                   role: "1", beginDate: "28.03.2022"},
        ]},

        { name: "УГРС",           amount: 20, Ok: false, data: [
            {name: "ЭлектроГазосварщик",        role: "4", beginDate: "28.03.2022"},
            {name: "Слесарь-сантехник",         role: "5", beginDate: "28.03.2022"},
            {name: "Оператор",                  role: "1", beginDate: "28.03.2022"},
        ]},

        { name: "ЛПУМГ",          amount: 15, Ok: false, data: [
            {name: "ЭлектроГазосварщик",        role: "4", beginDate: "28.03.2022"},
            {name: "Уборщик",                   role: "5", beginDate: "28.03.2022"},
            {name: "Оператор",                  role: "1", beginDate: "28.03.2022"},
        ]},

        { name: "УДиТГ",          amount: 4, Ok: false, data: [
            {name: "Разнорабочий",             role: "4", beginDate: "28.03.2022"},
            {name: "Прачка",                   role: "5", beginDate: "28.03.2022"},
        ]},

        { name: "СМУ",            amount: 10, Ok: false, data: [
            {name: "Монтажних",                 role: "4", beginDate: "28.03.2022"},
            {name: "Сметчик",                   role: "5", beginDate: "28.03.2022"},
            {name: "Строитель",                 role: "1", beginDate: "28.03.2022"},
        ]},
        
    ], 
    debts1:                          {
        data: [
            ["", "28.02.2021", "28.02.2022"],
            ["АУП",     150033,     127657],
            ["УГРС",    1048,       24163],
            ["ЛПУМГ",   109854,     25439],
            ["СМУ",     2953,       3314],
            ["Итого",   263878,     181023],
        ]
    },
    debts2:                          {
        data: [
            ["", "01.01.2021", "18.03.2022"],
            ["АУП",     150033,     127657],
            ["УГРС",    1048,       24163],
            ["ЛПУМГ",   109854,     25439],
            ["СМУ",     2953,       3314],
            ["Итого",   263878,     181023],
        ]
    },

    incindents:                          {
        data: [
            ["", "2021", "2022", "28.03.2022"],
            ["Распред.сети",                13,  0,  0],
            ["Магистральный газопровод",     0,  0,  0],
        ]
    },

    contexts:                          {
        data: [
            ["", "2022", "Сутки"] ,
            ["Аварийные",       5,  2],
            ["Не аварийные",    5,  3],
        ]
    },
    
    purchase:                          {
        data: [
            ["", "2021", "2022"] ,
            ["Количество (плана)",  1443,  628],
            ["Сумма плана",    4815469.58,  4509596.02],
        ],
        data2: [
            ["", "23.03.2021", "23.03.2022", "% выполнения плана"] ,
            ["Распред.сети",                432,        450,        71],
            ["Магистральный газопровод",    1262007,    2875732.39, 63.77],
            ["Экономия",                    70981,      46376.39,   0],
        ]
    },
    covid:                          [
        { tip: "Инфицированные, количество",    count: 0},
        { tip: "Коллективный иммунитет, %",     count: 95},
    ],
    valutes:    {
        USD:            88,
        EUR:            98,
    },
    deficit:                            {
        data: [
            ["", "2021", "2022"] ,
            ["План 2022",  1443,  628],
            ["Факт 2022",    4815469.58,  4509596.02],
        ],
        data2: [
            ["", "23.03.2021", "23.03.2022"] ,
            ["План 2022",                432,        450],
            ["Факт 2022",    1262007,    2875732.39],
        ]
    }
}


for(const [key, value] of Object.entries(i_state)){
    reducers.push(
        function (state = i_state[key], action) {
            switch(action.type){
                case key: {
                    if(typeof(value) === "object"){
                        if(Array.isArray(value)) {
                            return action[key]
                        } else {
                            let data: object; data = {};
                            for(const key1 of Object.keys(value)){ 
                                data[key1] = action[key1] === undefined ? state[key1] : action[key1]
                            }   
                            return data
                        }

                    } else return action[key]
                }
                default: return state;
            }       
        }

    )
}



export async function   getData(method : string, params){

    let res = await axios.post(
            URL + method, params
    ).then(response => response.data)
        .then((data) => {
            if(data.Код === 200) console.log(data) 
            return data
        }).catch(error => {
          console.log(error)
          return {error: true, message: error}
        })
    return res

}


export async function   getData1C(method : string, params){
    let user = {
        user:       "admin",
        password:   "1973"
    }
    let res = await axios.post(
              URL1C + method
            , params
            , {
                auth: {
                  username:  user.user,
                  password:  user.password
                }
            } 
    ).then(response => response.data)
        .then((data) => {
            if(data.Код === 200) console.log(data) 
            return data
        }).catch(error => {
          console.log(error)
          return {Код: 200}
        })
    return res

}

async function getValute(){
    let res = await axios.get(
       "https://www.cbr-xml-daily.ru/daily_json.js" 
    ).then(response => response.data)
        .then((data)=>{
            return data.Valute
        }).catch(error => {
            console.log("error")
        })
    return res
}   


function                create_Store(reducer, initialState) {
    var currentReducer = reducer;
    var currentState = initialState;
    var listeners: Array<any>; listeners = []
    return {
        getState() {
            return currentState;
        },
        dispatch(action) {
            currentState = currentReducer(currentState, action);
            listeners.forEach((elem)=>{
                if(elem.type === action.type){
                    elem.func();
                }
            })
            return action;
        },
        subscribe(listen: any) {
            var ind = listeners.findIndex(function(b) { 
                return b.num === listen.num; 
            });
            if(ind >= 0){
                listeners[ind] = listen;
            }else{
                listeners = [...listeners, listen]
            }
        },
        unSubscribe(index) {
            var ind = listeners.findIndex(function(b) { 
                return b.num === index; 
            });
            if(ind >= 0){
                listeners.splice(ind, 1)
            }        
        }
    };
}

const                   rootReducer = combineReducers({

        auth:                       reducers[0],
        route:                      reducers[1],
        login:                      reducers[2],
        grid1:                      reducers[3],
        grid2:                      reducers[4],
        grid3:                      reducers[5],
        miss1:                      reducers[6],
        vacanse1:                   reducers[7],
        debts1:                     reducers[8],
        debts2:                     reducers[9],
        incindents:                 reducers[10],
        contexts:                   reducers[11],
        purchase:                   reducers[12],
        covid:                      reducers[13],
        valutes:                    reducers[14],
        deficit:                    reducers[15],

})

export const Store      =  create_Store(rootReducer, i_state)

export const URL1C      = "https://fhd.aostng.ru/dashboard/hs/DASH_API/V1/"

//export const URL      = "https://marketac.ru:49002/node/"

export const URL        = "https://fhd.aostng.ru/vesta/hs/API_STNG/V2/"



export function Phone(phone): string {
    if(phone === undefined) return ""
    if(phone === null) return ""
    let str = "+"
    for(let i = 0;i < phone.length;i++){
      let ch = phone.charCodeAt(i)
      if( ch >= 48 && ch <= 57) str = str + phone.charAt(i)
    }
    return str
}

export async function getProfile(phone){
    Store.dispatch({type: "auth", auth: true });
    let res = await getData("method", {
            method: "Профиль",
            phone:  Phone(phone),
        })
    let login = res[0];login.type = "login"
    Store.dispatch( login )
}


let timerId;

async function getDatas(){

    console.log("getDatas")

    let valutes = Store.getState().valutes
    let vals = await getValute()
    valutes.USD = vals.USD.Value
    valutes.EUR = vals.EUR.Value
    Store.dispatch({type: "valutes", valutes: valutes})

    let res = await getData1C("ПроизводственныеПоказатели", {})
    Store.dispatch( res.grid1 )
    Store.dispatch( res.grid2 )
    Store.dispatch( res.grid3 )

    res = await getData1C("Сотрудники", {})
    Store.dispatch({type: "miss1", miss1: res})

    res = await getData1C("Вакансии", {})
    Store.dispatch({type: "vacanse1", vacanse1: res})

    res = await getData1C("Долги", {})
    Store.dispatch({type: "debts1", data: res.debts1.data})
    Store.dispatch({type: "debts2", data: res.debts2.data})

    res = await getData1C("Инциденты", {})
    Store.dispatch({type: "incindents", data: res.data})

    res = await getData1C("Заявки", {})
    Store.dispatch({type: "contexts", data: res.data})

    res = await getData1C("Заявки", {})
    Store.dispatch({type: "contexts", data: res.data})

    res = await getData1C("Закупки", {})
    Store.dispatch({type: "purchase", data: res.data, data2: res.data2})

    res = await getData1C("COVID", {})
    Store.dispatch({type: "covid", covid: res})

    res = await getData1C("Дефицит", {})
    Store.dispatch({type: "deficit", data: res.data, data2: res.data2})

}


export async function   updateData(){
    getDatas();
    timerId = setInterval(() => {
         getDatas()        
    }, 120000);
  
}


export function         stopOrders(){

  //    setTimeout(() => { clearInterval(timerId) });

}

async function          exec(){
   updateData()
  // getDatas()
}

exec();
