import { IonButton, IonContent, IonText } from '@ionic/react';
import './PaymentSuccess.css';
import { Redirect, useHistory, useParams } from 'react-router';
import { useEffect } from 'react';
import { getData } from './Store';

export function PaymentSuccess( props):JSX.Element {

    const { name } = useParams<{ name: string; }>();

    console.log(name)

    
    useEffect(()=>{
        async function load( id ){
            console.log(id)
            let res = await getData("ReturnPay", {
                orderId: id
            })
            console.log(res);
        }
        let str = props.location.search
        str = str.substring(1);
        let arr1 = str.split("&")
        let arr = arr1[0].split("=")
         load( arr[1] )

    },[])

    let hist = useHistory();
    let elem = <>
        <div
            className="p-content w-100 h-100"
        > 
            <div id="pspattern" className='pattern pt-4'>
                <div className='pt-4'>
                    <div className='ml-auto mr-auto w-70 a-center mt-4'>
                        <IonText className='fs-3 cl-white a-center'>Оплата прошла успешно</IonText>
                    </div>
                    {/* <div className='ml-auto mr-auto w-70 mt-1'>
                        <IonButton 
                            expand='block'
                            fill='outline'
                            color='success'
                            onClick={()=>{
                                window.location.reload();
                                //hist.push("/return")       
                            }}
                        >
                            Вернуться в приложение
                        </IonButton>
                    </div> */}
                </div>
            </div>
        </div>
    </>
    return elem
}

export function PaymentError():JSX.Element {

    let hist = useHistory();
    let elem = <>
        <div
            className="p-content w-100 h-100"
        > 
            <div id="pspattern" className='pattern pt-4'>
                <div className='pt-4'>
                    <div className='ml-auto mr-auto w-70 a-center mt-4'>
                        <IonText className='fs-3 cl-white a-center'>Ой, что то пошло не так...</IonText>
                    </div>
                    {/* <div className='ml-auto mr-auto w-70 mt-1'>
                        <IonButton 
                            expand='block'
                            color='danger'
                            fill='outline'
                            onClick={()=>{
                                hist.push("/return")       
                            }}
                        >
                            Вернуться в приложение
                        </IonButton>
                    </div> */}
                </div>
            </div>
        </div>
    </>
    return elem
}