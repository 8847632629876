import { IonButton, IonInput, IonLoading } from "@ionic/react";
import { useEffect, useState } from "react"
import { getData1C, Store } from "../pages/Store";
import MaskedInput from "../mask/reactTextMask";
import "./Login.css"


export function Login(props):JSX.Element {
    const [ load, setLoad] = useState(false)

    useEffect(()=>{
        console.log("use")
        let lg = Store.getState().login
        console.log(lg)
        if( lg.Наименование === "") {
            let sav1= localStorage.getItem("fhd.login") as string;
            let sav = JSON.parse(sav1)
            if( sav !== null) {
                sav.type = "login"
                Store.dispatch( sav )
                console.log( sav )
            } 
        } 
    }, [])

    function Login():JSX.Element {
        const [ info, setInfo ] = useState( Store.getState().login )

        useEffect(()=>{
            setInfo( Store.getState().login )
        },[])
        
        Store.subscribe({num: 201, type: "login", func: ()=>{
            setInfo( Store.getState().login )
        }})

        async function load(){
            setLoad(true)
            let res = await getData1C("Авторизация", info )
            if(res.Код === 100) {
                localStorage.setItem("fhd.login", JSON.stringify(res.Данные));
                Store.dispatch( res.Данные )
                Store.dispatch({type: "auth", auth: 1})   
                setLoad(false)
            } else {
                info.Пинкод = ""
                info.type = "login"
                Store.dispatch( info )
                setLoad(false)
            }
        }

            let elem = <>
            <div className="l-content">
                <div className="l-div-1">
                    <div>
                        <b>Логин</b>
                        <IonInput
                            class="l-input"
                            value={ info.Логин }
                            onIonChange={(e)=>{
                               info.Логин = e.detail.value     
                            }}
                        />
                    </div>
                    <div className="mt-1">
                        <b>Пароль</b>
                        <IonInput
                            class="l-input"
                            type = "password"
                            value={ info.Пинкод }
                            onIonChange={(e)=>{
                                info.Пинкод = e.detail.value     
                             }}
                         />
                    </div>
                    <div className="mt-1 flex fl-space">
                        <div></div>
                        <IonButton
                            class="l-button"
                            onClick={()=>{
                                load()
                            }}
                        >
                            Вход
                        </IonButton>
                    </div>
                </div>
            </div>
        
        </>
        return elem
    }

    
    let elem = <Login />
    return <>
        <IonLoading message= "Подождите..." isOpen= { load }/>
        { elem }
    </>
}

export function User(props):JSX.Element {

    function User():JSX.Element {
        const [ info, setInfo ] = useState( Store.getState().login )
        const [ upd, setUpd] = useState( 0 )

        useEffect(()=>{
            setInfo( Store.getState().login )
        },[])
        
        async function load(){
            console.log(info)
            let res = await getData1C("Сохранить", info )
            console.log(res)
            if(res.Код === 100) {
                Store.dispatch( res.Данные )
                Store.dispatch({type: "auth", auth: 1})   
            } 
        }

        let elem = <>
            <div className="l-content">
                <div className="l-div-1">
                    <div>
                        <b>Логин</b>
                        <IonInput
                            class="l-input"
                            value={ info.Логин }
                            onIonChange={(e)=>{
                               info.Логин = e.detail.value     
                            }}
                        />
                    </div>
                    <div className="mt-1">
                        <b>Пароль</b>
                        <IonInput
                            class="l-input"
                            type = "password"
                            value={ info.Пинкод1 === undefined ? "" : info.ПинКод1 }
                            onIonChange={(e)=>{
                                info.Пинкод1 = e.detail.value     
                             }}
                         />
                    </div>
                    <div className="mt-1">
                        <b>Повторить пароль</b>
                        <IonInput
                            class="l-input"
                            type = "password"
                            value={ info.Пинкод2 === undefined ? "" : info.ПинКод2 }
                            onIonChange={(e)=>{
                                info.Пинкод2 = e.detail.value     
                             }}
                         />
                    </div>
                    <div className="mt-1 flex fl-space">
                        <IonButton
                            class="l-button"
                            onClick={()=>{
                                console.log(info)
                                if( info.Пинкод1 !== undefined && info.ПинКод1 !== ""){
                                    if(info.Пинкод1 === info.Пинкод2) {
                                        info.Пинкод = info.Пинкод1
                                        load() 
                                    } else {
                                        info.ПинКод1    = ""
                                        info.Пинкод2    = ""
                                        setUpd(upd + 1)
                                    }    
                                }   

                            }}
                        >
                            Сменить
                        </IonButton>
                        <IonButton
                            class="l-button"
                            onClick={()=>{
                                Store.dispatch({type : "auth", auth: 1})
                            }}
                        >
                            Выйти
                        </IonButton>
                    </div>
                </div>
            </div>
        
        </>
        return elem
    }

    let elem  = <>
        <User />
    </>
    return elem
}
